export const repertoireArray = [
  {
    title: 'Najświeższe brzmienia z ostatnich miesięcy',
    songs: [
      'Amazing - Seal',
      'Blurred Lines – Robin Thicke',
      'California Gurls – Katy Perry feat. Snoop Dogg',
      'Call Me Maybe - Carly Rae Jepsen',
      'Can`t Stop The Feeling – Justin Timberlake',
      'Crazy In Love - Beyonce',
      'Domino – Jassie J',
      'Get Lucky – Daft Punk feat. Pharrell Williams',
      'Happy- Pharrel Williams',
      'I Can`t Feel My Face – The Weeknd',
      'I Kissed A Girl - Katy Perry',
      'Impossible - James Arthur',
      'I Need Your Love - Calvin Harris',
      'Just The Way You Are – Bruno Mars',
      'Locked Out In Heaven – Bruno Mars',
      'Low – Flo Rida ft. T- Pain',
      'Lush Life – Zara Larsson',
      'Monster - Rihanna feat. Eminem',
      'Move In The Right Direction – Gossip',
      'Price Tag – Jessie J',
      'Only Girl – Rihanna',
      'Shake It Of – Taylor Swift',
      'Shut Up And Dance – Walk The Moon',
      'There`s Nothing Holding Me Back – Shawn Mendes',
      'Thinking About You- Calvin Harris',
      'Titanium - David Guetta',
      'Treasure - Bruno Mars',
      'UpTown Funk – Bruno Mars',
      'Want To Want Me – Jason Derulo',
      'Waiting All Night - Rudimental',
      'Wake Me Up – Avicii',
      'You Make Me – Avicii'
    ]
  },
  {
    title: 'Współczesne Taneczne Hity',
    songs: [
      'Corazon Espinado – Santana feat. Mana',
      'Everybody – Backstreet Boys',
      'Give Me Baby One More Time – B. Spears',
      'Horny - Mousse T.',
      'I Follow Rivers - Lykke Li',
      'I\'ll Be There For You - (z czołówki "Przyjaciele")',
      'Lazy Song - Bruno Mars',
      "Let's Get Loud - Jennifer Lopez",
      'Macarena - Los Del Rio',
      'Maniac – M. Sembello',
      'Moves Like Jagger- Maroon 5',
      "Please Don't Stop The Music – Rihanna",
      'Rolling In The Deep - Adele',
      'Rude Boy – Rihanna',
      'Sex Bomb – Tom Jones',
      'S&M - Rihanna',
      'Smooth – Santana',
      'This Love - Maroon 5',
      'Wannabe – Spice Girls',
      'We Found Love – Rihanna'
    ]
  },
  {
    title: "'Szał Na Parkiecie' czyli Imprezowe Klasyki",
    songs: [
      'Ai Se Eu Te Pego (Nossa Nossa) - Michel Telo',
      'Bad Girls - Donna Summer',
      'Billie Jean - Michael Jackson',
      'Celebration - Kool And The Gang',
      "Cry Just A Little Bit - Shakin' Stevens",
      'Dreadlock Holiday - 10cc',
      'Hot stuff - Donna Summer',
      "I'm So Excited - Pointer Sisters",
      'I Wanna Dance With Somebody-Whitney Houston',
      'I Will Survive - Gloria Gaynor',
      'Love Is In The Air - John Paul Young',
      'Maniac – M. Sembello',
      'Nah Neh Nah - Vaya Con Dios',
      'Pretty Woman - Roy Orbison',
      'Rather Be – Clean Bandit',
      'Simply The Best - Tina Turner',
      "Stayin' Alive - Bee Gees",
      'Sunny - Boney M',
      'Sway - Michael Buble',
      'Time Of My Life – Dirty Dancing',
      'YMCA - Village People',
      "You're The All That I Want – (musical 'Greace')",
      'Volare - Gipsy Kings',
      'We Are Family - Sister Sledge',
      'What Is Love – Haddaway'
    ]
  },
  {
    title: "Ale nie ma to jak prawdziwy rock 'and' roll...",
    songs: [
      'Blue Suede Shoes - Elvis Presley',
      'Footloose – Kenny Loggins',
      'Hound Dog - Elvis Presley',
      'I Feel Good - James Brown',
      'La Bamba - Ritchie Valens',
      "Let's Twist Again - Chubby Checker",
      'Rock Around The Clock - Bill Haley',
      'Twist And Shout - The Beatles'
    ]
  },
  {
    title: 'Ponadczasowe, wybitne głosy',
    songs: [
      "Ain't Nobody – Chaka Khan",
      'Always On My Mind - Elvis Presley',
      "A Woman's Worth - Alicia Keys",
      'Bądź Moim Natchnieniem - Andrzej Zaucha',
      'Englishman In New York – Sting',
      'Isn`t She Lovely - Stevie Wonder',
      'My Way - Frank Sinatra',
      "What's Love Got To Do With It - Tina Turner",
      'Wspomnienie – Czesław Niemen',
      'You Might Need Somebody - Shola Ama'
    ]
  },
  {
    title: 'Tylko we dwoje – najpiękniejsze ballady',
    songs: [
      'Can`t Take My Eyes Off You - Frankie Valli',
      'Careless Whisper - George Michael',
      'Dumka na dwa serca – E.Górniak&M.Szcześniak',
      'Every Breath You Take - Sting & The Police',
      "Fallin' - Alicia Keys",
      "Girl You'll Be A Woman – (z flmu Pulp Piction)",
      'How Deep Is Your Love - Bee Gees',
      'I Just Called To Say I Love You – Stevie Wonder',
      "If You Don't Know Me By Now - Simply Red",
      "If I Ain't Got You - Alicia Keys",
      'Stand By Me - Ben E. King',
      'Smooth Operator - Sade',
      'What A Wonderful World – Louis Armstrong',
      'When A Man Loves A Woman – Michael Bolton'
    ]
  },
  {
    title: "W stylu 'vintage'",
    songs: [
      'Cała Sala - Jerzy Połomski',
      'Cicha Woda – M. Maleńczuk (Psychodancing)',
      'Czarny Alibaba – Andrzej Zaucha',
      'Czy Te Oczy Mogą Kłamać - Jan Pietrzak',
      'Diana - Paul Anka',
      'Dwudziestolatki - Maciej Kossowski',
      'Historia Jednej Znajomości - Czerwone Gitary',
      'Hit The Road Jack - Ray Charles',
      'Jedziemy Autostopem - Karin Stanek',
      'Ktoś mnie pokochał – Skaldowie',
      'Małgośka - Maryla Rodowicz',
      'Mercy – Duffy',
      'Mrs Robinson – Kimon & Garfunkel',
      'Nigdy Więcej – Pudelsi',
      'Ostatnia Niedziela - Mieczysław Fogg',
      'Pod papugami - Czesław Niemen',
      'Przetańczyć Z Tobą Chcę Całą Noc – A. Jantar',
      'Rehab - Amy Winehouse',
      'Sing Sing – Maryla Rodowicz',
      'Tak Bardzo Się Starałem - Czerwone Gitary',
      'Trzynastego - Kasia Sobczyk',
      'Tyle Słońca W Całym Mieście - Anna Jantar',
      'Valerie - Amy Winehouse'
    ]
  },
  {
    title: 'Ukłon w stronę polskiej zabawy...',
    songs: [
      'Bananowy Song – Vox',
      'Biała Armia -Bajm',
      'Cała noc - Hey',
      'Dziś Prawdziwych Cyganów Już Nie Ma – Maryla',
      'Gdy Nie Ma Dzieci – Kult',
      'Jak nie my to kto – Mrozu ft. Tomson',
      'Jedzie Pociąg - Ryszard Rynkowski',
      'Jenny – Edyta Bartosiewicz',
      'Jestem kobietą – Edyta Górniak',
      'Kiedyś Cię znajdę – Reni Jusis',
      'Miałeś być – Brodka',
      'Na dwa - Sistars',
      'Na Językach – Kayah',
      'Ostatni Raz Zatańczysz Ze Mną – K. Krawczyk',
      'Parostatek – Krzysztof Krawczyk',
      'Pocałuj noc – Various Manx',
      'Randez Voux - Kombii',
      'Słodkiego Miłego Życia- Kombi',
      'Statki Na Niebie - De Mono',
      'Sutra - Sistars',
      'Takie Tango - Budka Sufera',
      'Ta sama chwila - Bajm',
      'Testosteron –Kayah',
      'W biegu – N. Kukulska',
      'Wehikuł Czasu – Dżem',
      'Zakręcona – Reni Jusis',
      'Znamy Się Tylko Z Widzenia - Trubadurzy'
    ]
  },
  {
    title: 'Gitarowe Fascynacje',
    songs: [
      'Chcemy być sobą – Perfect',
      'Eye Of The Tiger – Survivor',
      'Kryzysowa Narzeczona - Lady Pank',
      'Mniej Niż Zero – Lady Pank',
      'Nie Płacz Ewka - Perfect',
      'Satisfaction - The Rolling Stones'
    ]
  }
];
